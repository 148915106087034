.home-container {
  margin-bottom: 300px;
  .horizontal-ad-1 {
    // border: 1px solid red;
    // height: 100px;
    
  }
  .second-section {
    display: flex;
    margin-top: 10px;
    margin-bottom: 0;
    .main-article {
      width: 70%;
      margin-right: 10px;
    }
    .latest-articles {
      width: 30%;
      display: flex;
      flex-direction: column;
    }
  }
  .section-4 {
    width: 100%;

    margin-top: 30px;
  }
  .section-5 {
    // border: 5px solid orange;
    margin-top: 30px;
  }
  .wrapper-6-7 {
    // padding-top: 50px;
    margin-top: 30px;

    margin-bottom: 10px;
    border-radius: 10px;

    .section-6 {
      border-radius: 10px;
      border-radius: 10px;

      .blog-container-6-left {
      }
    }
    .section-7 {
      border-radius: 10px;

      .blog-container-7-left {
      }
    }
  }
}
@media (max-width: 900px) {
}
@media (max-width: 500px) {
  .second-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
    .main-article {
      width: 100% !important;
      margin-right: 10px;
    }
    .latest-articles {
      width: 100% !important;
      display: flex;
      flex-direction: column;
    }
  }
  .wrapper-6-7 {
    // padding-top: 50px;
    margin-top: 30px;

    margin-bottom: 10px;
    border-radius: 10px;

    .section-6 {
      border-radius: 10px;
      border-radius: 10px;

      .blog-container-6-left {
        margin-bottom: 10px;
      }
    }
    .section-7 {
      border-radius: 10px;

      .blog-container-7-left {
      }
    }
  }
}
