.navbar-container {
  // border: 1px solid red;
  background: black;

  display: flex;
  justify-content: space-between;
  // padding-left: 5%;
  // padding-right: 5%;

  .logo-container {
    display: flex;
  }
  .navbar-logo {
    display: flex;
    text-decoration: none;
  }
  .logo-header {
    margin-right: 5px;
    color: #00471b;
    color: orange;
  }
  .logo-header2 {
    color: purple;
  }
  .icons-wrapper {
    // border: 1px solid pink;
    display: flex;
    align-items: center;
    .icon-1 {
      margin-right: 25px;
    }
  }
}
