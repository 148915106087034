.blog-tile-container {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  background: rgba(20, 20, 20, 1);
  border-radius: 10px;
  margin-top: 20px;
  .blog-tile-link {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    text-decoration: none;

    .blog-tile-title-container {
      width: 50%;
      height: auto;

      .blog-tile-header {
        color: white;
        font-size: 2.15rem;
        padding-top: 7%;
        padding-left: 25px;
        padding-right: 25px;
        margin: 0;
      }
      .blog-tile-header:hover {
        color: purple;
      }
      .blog-tile-description {
        color: white;
        font-size: 1.15rem;
        padding-left: 25px;
        padding-right: 25px;
        margin: 0;
        color: lightgray;
        margin-top: 20px;
      }
      // .read-more-btn {
      //   margin-top: 20px;
      //   .read-more-link {
      //     color: white;
      //     font-size: 1.2rem;
      //     padding-left: 5%;
      //     padding-right: 5%;
      //     margin: 0;
      //     font-weight: bold;
      //     text-decoration: none;
      //     color: white;
      //   }
      //   .read-more-link:hover {
      //     color: orange;
      //   }
      // }
    }
    .blog-tile-img-container {
      width: 50%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .blog-tile-img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }
}

.blog-tile-2-container {
  background: rgba(20, 20, 20, 1);
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;

  .blog-tile-2-container:hover {
  }

  .blog-tile-2-link {
    width: 100%;
    display: flex;
    text-decoration: none;
  }
  .blog-tile-2-wrapper {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;

    .blog-tile-2-img-wrapper {
      width: 40%;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .blog-tile-2-img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
    .blog-tile-2-text {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 3%;

      .blog-tile-2-header {
        width: 100%;
        margin: 0;
        color: white;
        text-decoration: none;
        margin-bottom: 0;
        font-size: 1.6rem;
      }
      .blog-tile-2-header:hover {
        color: purple;
      }

      .blog-p-tile-2 {
        font-size: 1rem;
        color: lightgray;
        margin: 0;
        margin-top: 10px;
      }
      .read-more-btn {
        margin-top: 0px;
        .read-more-link {
          color: white;
          font-size: 1.1rem;
          margin: 0;
          font-weight: bold;
          text-decoration: none;
          color: white;
        }
        .read-more-link:hover {
          color: purple;
        }
      }
    }
  }
}
.blog-container-3 {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  background: rgba(20, 20, 20, 1);
  // background: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  margin: 0;
  // height: 500px;

  .latest-news-h {
    text-align: left;
    color: #eee1c6;
    color: orange;

    font-size: 1.5rem;
    // text-transform: uppercase;
    border-bottom: 1px solid orange;
    padding-bottom: 5px;
    letter-spacing: 1px;
  }
  .trending-post {
    // border-bottom: 1px solid white;
    padding-bottom: 5px;
    // text-align: center;
  }
}

.blog-tile-3-wrapper {
  display: flex;
  align-items: center;
  .read-more-link {
    color: white;
  }
  .blog-tile-3-h {
    margin-left: 5px;
  }
}
.blog-tile-3-link {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
  font-size: 0.9rem;
}
.blog-tile-3-link:hover {
  color: purple;
}
.trending-h {
  text-align: left;
  color: #eee1c6;
  color: orange;

  font-size: 1.5rem;
  // text-transform: uppercase;
  border-bottom: 1px solid orange;
  padding-bottom: 5px;
  letter-spacing: 1px;
  // width: 25%;
}

.blog-tile-4-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  // padding: 10%;
  border-radius: 10px;
  background: rgba(20, 20, 20, 1);

  .blog-tile-4-link {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    display: flex;
  }
  .blog-tile-4-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin: 0;
    border-radius: 10px;

    .blog-tile-4-img-wrapper {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .blog-tile-4-img {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        border-radius: 10px;
      }
    }
    .blog-tile-4-text {
      width: 100%;
      display: flex;
      // padding-left: 10px;
      // padding-right: 10px;
      padding-bottom: 5%;

      .blog-tile-4-header {
        width: 100%;
        // padding-left: 10px;
        // padding-right: 10px;
        color: white;
        text-decoration: none;
        margin-bottom: 0;
        font-size: 1.2rem;
        // text-align: center;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 5%;
        padding-top: 2%;
      }
      .blog-tile-4-header:hover {
        color: purple;
      }
      .blog-p-tile-4 {
        font-size: 1.2rem;
        color: lightgray;
        margin: 0;
      }
    }
  }
}
.blog-tile-4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 2%;
  margin-bottom: 5px;
  .blog-tile-4-post {
    width: 24%;
  }
}

.blog-tile-5-container {
  background: rgba(20, 20, 20, 1);
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  .blog-tile-5-container:hover {
  }

  .blog-tile-5-link {
    width: 100%;
    display: flex;
    text-decoration: none;
  }
  .blog-tile-5-wrapper {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    .blog-tile-5-img-wrapper {
      width: 25%;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .blog-tile-5-img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
    .blog-tile-5-text {
      width: 65%;
      display: flex;
      flex-direction: column;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 3%;

      .blog-tile-5-header {
        width: 100%;
        margin: 0;
        color: white;
        text-decoration: none;
        margin-bottom: 0;
        font-size: 1.6rem;
      }
      .blog-tile-5-header:hover {
        color: purple;
      }

      .blog-p-tile-5 {
        font-size: 1rem;
        color: lightgray;
        margin: 0;
        margin-top: 10px;
      }
      .read-more-btn {
        margin-top: 0px;
        .read-more-link {
          color: white;
          font-size: 1.1rem;
          margin: 0;
          font-weight: bold;
          text-decoration: none;
          color: white;
        }
        .read-more-link:hover {
          color: purple;
        }
      }
    }
  }
}
.blog-tile-6-container {
  border-radius: 10px;
  background: rgba(20, 20, 20, 1);

  .blog-tile-6-link {
    color: white;
    text-decoration: none;
  }
  .blog-tile-6-wrapper {
    border-radius: 10px;
    .blog-tile-6-img-wrapper {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 25%;
      margin-right: 25%;
      padding-top: 5%;

      .blog-tile-6-img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .blog-tile-6-text {
      padding-bottom: 20px;

      .blog-tile-6-header {
        font-size: 1.5rem;
      }
      .blog-tile-6-header:hover {
        color: purple;
      }
      .blog-p-tile-6 {
      }
    }
  }
}
.blog-container-6-wrapper {
  display: flex;
  width: 100%;
  border-radius: 10px;

  .blog-container-6-left {
    display: flex;
    flex-direction: column;
    // padding-left: 5%;

    width: 50%;
    // background: rgba(20, 20, 20, 1);
    border-radius: 10px;
    margin-right: 5px;

    .blog-container-6-main {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  .blog-container-6-right {
    display: flex;
    flex-direction: column;
    // padding-right: 5%;

    width: 50%;
    // background: rgba(20, 20, 20, 1);
    border-radius: 10px;
    margin-left: 5px;
    .blog-container-6-main {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}
.blog-tile-7-container {
  display: flex;
  // padding-top: 10px;
  // padding-bottom: 10px;
  background: rgba(20, 20, 20, 1);
  border-radius: 10px;

  margin-top: 10px;
  .blog-tile-7-link {
    color: white;
    text-decoration: none;
  }
  .blog-tile-7-wrapper {
    display: flex;

    .blog-tile-7-img-wrapper {
      width: 25%;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .blog-tile-7-img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
    .blog-tile-7-text {
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      padding-top: 3%;

      .blog-tile-7-header:hover {
        color: purple;
      }
      .blog-p-tile-7 {
      }
    }
  }
}

@media (max-width: 900px) {
  .blog-tile-container {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    background: rgba(20, 20, 20, 1);
    border-radius: 10px;
    margin-top: 20px;
    .blog-tile-link {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;

      text-decoration: none;

      .blog-tile-title-container {
        width: 50%;
        height: auto;

        .blog-tile-header {
          color: white;
          font-size: 2.15rem;
          padding-top: 7%;
          padding-left: 25px;
          padding-right: 25px;
          margin: 0;
        }
        .blog-tile-header:hover {
          color: purple;
        }
        .blog-tile-description {
          color: white;
          font-size: 1.15rem;
          padding-left: 25px;
          padding-right: 25px;
          margin: 0;
          color: lightgray;
          margin-top: 20px;
        }
        // .read-more-btn {
        //   margin-top: 20px;
        //   .read-more-link {
        //     color: white;
        //     font-size: 1.2rem;
        //     padding-left: 5%;
        //     padding-right: 5%;
        //     margin: 0;
        //     font-weight: bold;
        //     text-decoration: none;
        //     color: white;
        //   }
        //   .read-more-link:hover {
        //     color: orange;
        //   }
        // }
      }
      .blog-tile-img-container {
        width: 50%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
    }
  }

  .blog-tile-2-container {
    background: rgba(20, 20, 20, 1);
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;

    .blog-tile-2-container:hover {
    }

    .blog-tile-2-link {
      width: 100%;
      display: flex;
      text-decoration: none;
    }
    .blog-tile-2-wrapper {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;

      .blog-tile-2-img-wrapper {
        width: 40%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-2-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
      .blog-tile-2-text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 3%;

        .blog-tile-2-header {
          width: 100%;
          margin: 0;
          color: white;
          text-decoration: none;
          margin-bottom: 0;
          font-size: 1.6rem;
        }
        .blog-tile-2-header:hover {
          color: purple;
        }

        .blog-p-tile-2 {
          font-size: 1rem;
          color: lightgray;
          margin: 0;
          margin-top: 10px;
        }
        .read-more-btn {
          margin-top: 0px;
          .read-more-link {
            color: white;
            font-size: 1.1rem;
            margin: 0;
            font-weight: bold;
            text-decoration: none;
            color: white;
          }
          .read-more-link:hover {
            color: purple;
          }
        }
      }
    }
  }
  .blog-container-3 {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    background: rgba(20, 20, 20, 1);
    // background: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    margin: 0;
    // height: 500px;

    .latest-news-h {
      text-align: left;
      color: #eee1c6;
      color: orange;

      font-size: 1.5rem;
      // text-transform: uppercase;
      border-bottom: 1px solid orange;
      padding-bottom: 5px;
      letter-spacing: 1px;
    }
    .trending-post {
      // border-bottom: 1px solid white;
      padding-bottom: 5px;
      // text-align: center;
    }
  }

  .blog-tile-3-wrapper {
    display: flex;
    align-items: center;
    .read-more-link {
      color: white;
    }
    .blog-tile-3-h {
      margin-left: 5px;
    }
  }
  .blog-tile-3-link {
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
    font-size: 0.9rem;
  }
  .blog-tile-3-link:hover {
    color: purple;
  }
  .trending-h {
    text-align: left;
    color: #eee1c6;
    color: orange;

    font-size: 1.5rem;
    // text-transform: uppercase;
    border-bottom: 1px solid orange;
    padding-bottom: 5px;
    letter-spacing: 1px;
    // width: 25%;
  }

  .blog-tile-4-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    // padding: 10%;
    border-radius: 10px;
    background: rgba(20, 20, 20, 1);

    .blog-tile-4-link {
      color: white;
      text-decoration: none;
      font-size: 1.2rem;
      display: flex;
    }
    .blog-tile-4-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
      margin: 0;
      border-radius: 10px;

      .blog-tile-4-img-wrapper {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .blog-tile-4-img {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          border-radius: 10px;
        }
      }
      .blog-tile-4-text {
        width: 100%;
        display: flex;
        // padding-left: 10px;
        // padding-right: 10px;
        padding-bottom: 5%;

        .blog-tile-4-header {
          width: 100%;
          // padding-left: 10px;
          // padding-right: 10px;
          color: white;
          text-decoration: none;
          margin-bottom: 0;
          font-size: 1.2rem;
          // text-align: center;
          padding-left: 5%;
          padding-right: 5%;
          padding-bottom: 5%;
          padding-top: 2%;
        }
        .blog-tile-4-header:hover {
          color: purple;
        }
        .blog-p-tile-4 {
          font-size: 1.2rem;
          color: lightgray;
          margin: 0;
        }
      }
    }
  }
  .blog-tile-4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 2%;
    margin-bottom: 5px;
    .blog-tile-4-post {
      width: 24%;
    }
  }

  .blog-tile-5-container {
    background: rgba(20, 20, 20, 1);
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    .blog-tile-5-container:hover {
    }

    .blog-tile-5-link {
      width: 100%;
      display: flex;
      text-decoration: none;
    }
    .blog-tile-5-wrapper {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      .blog-tile-5-img-wrapper {
        width: 25%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-5-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
      .blog-tile-5-text {
        width: 65%;
        display: flex;
        flex-direction: column;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 3%;

        .blog-tile-5-header {
          width: 100%;
          margin: 0;
          color: white;
          text-decoration: none;
          margin-bottom: 0;
          font-size: 1.6rem;
        }
        .blog-tile-5-header:hover {
          color: purple;
        }

        .blog-p-tile-5 {
          font-size: 1rem;
          color: lightgray;
          margin: 0;
          margin-top: 10px;
        }
        .read-more-btn {
          margin-top: 0px;
          .read-more-link {
            color: white;
            font-size: 1.1rem;
            margin: 0;
            font-weight: bold;
            text-decoration: none;
            color: white;
          }
          .read-more-link:hover {
            color: purple;
          }
        }
      }
    }
  }
  .blog-tile-6-container {
    border-radius: 10px;
    background: rgba(20, 20, 20, 1);

    .blog-tile-6-link {
      color: white;
      text-decoration: none;
    }
    .blog-tile-6-wrapper {
      border-radius: 10px;
      .blog-tile-6-img-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 25%;
        margin-right: 25%;
        padding-top: 5%;

        .blog-tile-6-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .blog-tile-6-text {
        padding-bottom: 20px;

        .blog-tile-6-header {
          font-size: 1.5rem;
        }
        .blog-tile-6-header:hover {
          color: purple;
        }
        .blog-p-tile-6 {
        }
      }
    }
  }
  .blog-container-6-wrapper {
    display: flex;
    width: 100%;
    border-radius: 10px;

    .blog-container-6-left {
      display: flex;
      flex-direction: column;
      // padding-left: 5%;

      width: 50%;
      // background: rgba(20, 20, 20, 1);
      border-radius: 10px;
      margin-right: 5px;

      .blog-container-6-main {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
    .blog-container-6-right {
      display: flex;
      flex-direction: column;
      // padding-right: 5%;

      width: 50%;
      // background: rgba(20, 20, 20, 1);
      border-radius: 10px;
      margin-left: 5px;
      .blog-container-6-main {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }
  .blog-tile-7-container {
    display: flex;
    // padding-top: 10px;
    // padding-bottom: 10px;
    background: rgba(20, 20, 20, 1);
    border-radius: 10px;

    margin-top: 10px;
    .blog-tile-7-link {
      color: white;
      text-decoration: none;
    }
    .blog-tile-7-wrapper {
      display: flex;

      .blog-tile-7-img-wrapper {
        width: 25%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-7-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
      .blog-tile-7-text {
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        padding-top: 3%;

        .blog-tile-7-header:hover {
          color: purple;
        }
        .blog-p-tile-7 {
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .blog-tile-container {
    margin-bottom: 30px;
    .blog-tile-link {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .blog-tile-title-container {
        width: 90%;
        margin-right: 5%;
        margin-left: 5%;

        .blog-tile-header {
          font-size: 1.5rem;
          padding: 0;
          margin: 0;
          padding-top: 30px;
        }

        .blog-tile-description {
          font-size: 1rem;
          padding: 0;
          margin: 0;
        }
      }
      .blog-tile-img-container {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
    }
  }

  .blog-tile-2-container {
    background: rgba(20, 20, 20, 1);
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;

    .blog-tile-2-link {
      width: 100%;
      display: flex;
      text-decoration: none;
    }
    .blog-tile-2-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: 0;

      .blog-tile-2-img-wrapper {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-2-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
      .blog-tile-2-text {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        // padding-left: 25px;
        // padding-right: 25px;

        .blog-tile-2-header {
          font-size: 1.5rem;
          padding-top: 30px;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
        }
        .blog-tile-2-header:hover {
          color: purple;
        }

        .blog-p-tile-2 {
          font-size: 1rem;
          color: lightgray;
          margin: 0;
          margin-top: 10px;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
        }
        .read-more-btn {
          margin-top: 0px;
          .read-more-link {
            color: white;
            font-size: 1.1rem;
            margin: 0;
            font-weight: bold;
            text-decoration: none;
            color: white;
          }
          .read-more-link:hover {
            color: purple;
          }
        }
      }
    }
  }
  .blog-container-3 {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    background: rgba(20, 20, 20, 1);
    // background: white;

    border-radius: 10px;
    margin: 0;
    // height: 500px;
    margin-bottom: 30px;
    margin-top: 30px;

    .latest-news-h {
      text-align: left;
      color: #eee1c6;
      color: orange;

      font-size: 1.5rem;
      // text-transform: uppercase;
      border-bottom: 1px solid orange;
      padding-bottom: 5px;
      letter-spacing: 1px;
    }
    .trending-post {
      // border-bottom: 1px solid white;
      padding-bottom: 5px;
      // text-align: center;
    }
  }

  .blog-tile-3-wrapper {
    display: flex;
    align-items: center;

    .read-more-link {
      color: white;
    }
    .blog-tile-3-h {
      margin-left: 10px;
    }
  }
  .blog-tile-3-link {
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
    font-size: 1rem;
  }
  .blog-tile-3-link:hover {
    color: purple;
  }
  .trending-h {
    text-align: left;
    color: #eee1c6;
    color: orange;

    font-size: 1.5rem;
    // text-transform: uppercase;
    border-bottom: 1px solid orange;
    padding-bottom: 5px;
    letter-spacing: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    // width: 25%;
  }

  .blog-tile-4-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    // padding: 10%;
    border-radius: 10px;
    background: rgba(20, 20, 20, 1);
    margin-bottom: 30px;

    .blog-tile-4-link {
      color: white;
      text-decoration: none;
      font-size: 1.5rem;
      display: flex;
    }
    .blog-tile-4-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
      margin: 0;
      border-radius: 10px;

      .blog-tile-4-img-wrapper {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .blog-tile-4-img {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          border-radius: 10px;
        }
      }
      .blog-tile-4-text {
        width: 100%;
        display: flex;

        // padding-left: 10px;
        // padding-right: 10px;
        padding-bottom: 5%;

        .blog-tile-4-header {
          // padding-left: 10px;
          // padding-right: 10px;
          color: white;
          text-decoration: none;
          margin-bottom: 0;
          font-size: 1.5rem;
          // text-align: center;
          padding-left: 5%;
          padding-right: 5%;
          // padding-bottom: 5%;
          padding: 0;
          margin: 0;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
          padding-top: 30px;
          padding-bottom: 30px;
        }
        .blog-tile-4-header:hover {
          color: purple;
        }
        .blog-p-tile-4 {
          font-size: 1.2rem;
          color: lightgray;
          margin: 0;
        }
      }
    }
  }
  .blog-tile-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // padding-top: 2%;
    margin-bottom: 5px;
    .blog-tile-4-post {
      width: 100%;
    }
  }

  .blog-tile-5-container {
    background: rgba(20, 20, 20, 1);
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    .blog-tile-5-container:hover {
    }

    .blog-tile-5-link {
      width: 100%;
      display: flex;

      text-decoration: none;
    }
    .blog-tile-5-wrapper {
      display: flex;
      flex-direction: column;

      width: 100%;
      margin: 0;
      padding: 0;
      .blog-tile-5-img-wrapper {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-5-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
      .blog-tile-5-text {
        padding: 0;
        margin: 0;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        .blog-tile-5-header {
          width: 100%;
          margin: 0;
          color: white;
          text-decoration: none;
          margin-bottom: 0;
          font-size: 1.5rem;
        }
        .blog-tile-5-header:hover {
          color: purple;
        }

        .blog-p-tile-5 {
          font-size: 1rem;
          color: lightgray;
          margin: 0;
          margin-top: 10px;
        }
        .read-more-btn {
          margin-top: 0px;
          .read-more-link {
            color: white;
            font-size: 1.1rem;
            margin: 0;
            font-weight: bold;
            text-decoration: none;
            color: white;
          }
          .read-more-link:hover {
            color: purple;
          }
        }
      }
    }
  }
  .blog-tile-6-container {
    border-radius: 10px;
    background: rgba(20, 20, 20, 1);
    margin-bottom: 30px;

    .blog-tile-6-link {
      color: white;
      text-decoration: none;
    }
    .blog-tile-6-wrapper {
      border-radius: 10px;
      .blog-tile-6-img-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;

        .blog-tile-6-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .blog-tile-6-text {
        padding-bottom: 20px;

        .blog-tile-6-header {
          font-size: 1.5rem;
          padding: 0;
          margin: 0;
          text-align: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
          padding-top: 30px;
          padding-bottom: 30px;
          // padding-left: 10px;
          // padding-right: 10px;
          // text-align: left;
        }
        .blog-tile-6-header:hover {
          color: purple;
        }
        .blog-p-tile-6 {
        }
      }
    }
  }
  .blog-container-6-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;

    .blog-container-6-left {
      display: flex;
      flex-direction: column;
      // padding-left: 5%;

      width: 100%;
      // background: rgba(20, 20, 20, 1);
      border-radius: 10px;
      margin-right: 5px;

      .blog-container-6-main {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
    .blog-container-6-right {
      display: flex;
      flex-direction: column;
      // padding-right: 5%;

      width: 100%;
      // background: rgba(20, 20, 20, 1);
      border-radius: 10px;
      margin-left: 5px;
      .blog-container-6-main {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }
  .blog-tile-7-container {
    display: flex;
    // padding-top: 10px;
    // padding-bottom: 10px;
    background: rgba(20, 20, 20, 1);
    border-radius: 10px;

    margin-top: 15px;
    margin-bottom: 15px;
    .blog-tile-7-link {
      color: white;
      text-decoration: none;
    }
    .blog-tile-7-wrapper {
      display: flex;

      .blog-tile-7-img-wrapper {
        width: 35%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog-tile-7-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
      .blog-tile-7-text {
        // padding-left: 15px;
        // padding-right: 15px;
        display: flex;
        // padding-top: 3%;
        padding: 0;
        font-size: 1rem;

        width: 90%;
        margin-left: 5%;
        margin-right: 5%;

        .blog-tile-7-header:hover {
          color: purple;
        }
        .blog-p-tile-7 {
        }
      }
    }
  }
}
