// BlogDetail.js
.blog-detail-container {
  background: rgba(20, 20, 20, 1);
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 10px;
  margin: 0;
  padding-bottom: 50px;
  margin-bottom: 300px;
  .blog-header {
    color: white;
    font-size: 2.15rem;
    padding-top: 50px;
    padding-bottom: 25px;

    text-align: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .inner-PostContainer {
    color: white;
    font-size: 1.2rem;
  }
  .sidebar-container {
    width: 100%;
  }
  //   .related-news-sidebar {

  //   }
  // .sidebar-content {

  // }
  // .sidebar-head {

  // }
  // .related-news {

  // }
}
// BlogDetailTile.js and BlogTileSide.js
.blog-post {
  margin-bottom: 20px;
  // margin-top: 20px;
  border-bottom: 2px solid black;
  .ImageContainer {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 30px;
    .Image {
      width: 100%;
      height: auto;
    }
  }
  #post-date {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .TDContainer {
    border-top: 1px solid purple;
    // padding-top: 30px;

    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 500px) {
  .blog-header {
    text-align: left;
  }
}
